import { Component, inject } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { AuthorizationService } from 'src/app/lib/core/authorization.service';
import { AsyncPipe } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [AvatarModule, MenuModule, AsyncPipe, SkeletonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {
  authInfo = inject(AuthorizationService).authInfo;
  authService = inject(AuthorizationService);

  items: MenuItem[] = [
    {
      icon: 'pi pi-user',
      label: 'Сменить пароль',
      command: () => {
        window.location.href =
          environment.backofficeUrl + 'admin/change-password';
      },
    },
    {
      icon: 'pi pi-sign-out',
      label: 'Выход',
      command: () => {
        this.authService.logout().mutate({});
      },
    },
  ];
}
