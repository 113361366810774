<div class="h-full flex flex-col gap-8 pb-16">
  @if(breadcrumbItems().length > 0) {
  <p-breadcrumb
    [model]="breadcrumbItems()"
    class="max-w-full"
    styleClass="border-none px-0"
  ></p-breadcrumb>
  }

  <heading-one> {{ title() }} </heading-one>

  <ng-content></ng-content>
</div>
