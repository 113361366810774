import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { injectMutation, injectQuery, injectQueryClient } from '@ngneat/query';
import { MessageService } from 'primeng/api';
import { QUERY_KEYS } from '../lib/constants';
import { User } from '@model/users';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  #query = injectQuery();
  #http = inject(HttpClient);
  #mutation = injectMutation();
  #queryClient = injectQueryClient();
  messageService = inject(MessageService);
  router = inject(Router);

  getUserList() {
    return this.#query({
      queryKey: [QUERY_KEYS.USERS],
      queryFn: () =>
        this.#http.get<User[]>(
          environment.apiUrlPrefix + 'nbrk/get-user-list',
          {
            withCredentials: true,
          }
        ),
    });
  }

  getSingleUser(id: number) {
    return this.#query({
      queryKey: [QUERY_KEYS.USERS, id],
      queryFn: () =>
        this.#http.get<User>(environment.apiUrlPrefix + 'nbrk/get-user', {
          withCredentials: true,
          params: { id },
        }),
    });
  }

  saveUser() {
    return this.#mutation({
      mutationFn: ({ userBody }: { userBody: Partial<User> }) => {
        return this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/save-user',
          new URLSearchParams({
            request: JSON.stringify(userBody),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
      },
      onSuccess: (res, { userBody }) => {
        if (!!userBody.id) {
          this.messageService.add({
            severity: 'success',
            detail: 'Пользователь обновлен',
          });
        } else {
          this.messageService.add({
            severity: 'success',
            detail: 'Пользователь добавлен',
          });
          this.router.navigate(['/roles']);
        }
      },
      onError: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.message,
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries();
      },
    });
  }
}
