<app-page-layout title="Заказы НБРК">
  <app-filters></app-filters>
  <div class="grow">
    @if (!!error) {
    <p class="text-red-400">
      {{ error.message || "Что-то пошло не так" }}
    </p>

    } @if (isPending) {
    <p-skeleton height="500px" width="100%" />

    } @if (!!orders && orders.length > 0) {
    <p-table
      [scrollable]="true"
      [loading]="isPending"
      scrollHeight="50vh"
      [columns]="cols"
      [value]="orders"
      class="grow h-full w-full"
      styleClass="p-datatable-gridlines p-datatable-sm"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (item of columns; track item.id) {
          <th class="min-w-24">{{ item.header }}</th>
          }
          <th class="w-5"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          @for (col of columns; track col.field) { @if (col.field ===
          'dateOfReceipt') {
          <td>{{ rowData[col.field] | date : "dd.MM.yyyy" }}</td>

          } @else {

          <td>{{ rowData[col.field] }}</td>
          } }
          <td>
            <a
              [routerLink]="rowData.id"
              [queryParams]="filters"
              class="text-primary underline hover:opacity-80 transition-colors"
            >
              Детали
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="9">
            <p class="text-center">Нет данных</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
    }
  </div>
</app-page-layout>
