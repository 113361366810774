import { Component } from '@angular/core';
import { HeadingOneComponent } from '@components/heading-one/heading-one.component';
import { ManageRolesComponent } from '../../components/manage-roles/manage-roles.component';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [HeadingOneComponent, ManageRolesComponent, PageLayoutComponent],
  templateUrl: './roles.component.html',
})
export class RolesComponent {}
