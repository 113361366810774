import { Component, inject } from '@angular/core';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { TableColumn } from '@model/table-column';
import { TableModule, TablePageEvent } from 'primeng/table';
import { OrdersService } from 'src/app/services/orders.service';
import { SkeletonModule } from 'primeng/skeleton';
import { FiltersComponent } from './filters/filters.component';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { DataEntity } from '@model/orders';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OrderFilters } from '@model/order-filters';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    HeadingOneComponent,
    TableModule,
    SkeletonModule,
    FiltersComponent,
    PageLayoutComponent,
    RouterLink,
    DatePipe,
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
})
export class OrdersComponent {
  private ordersService = inject(OrdersService);
  route = inject(ActivatedRoute);
  rows = 100;
  first = 0;
  filters = {
    status: null,
    fio: null,
    iin: null,
    page: this.first,
    pageSize: this.rows,
  } as OrderFilters;

  orders: DataEntity[] = [];
  isLoading: boolean = true;
  isPending: boolean = false;
  error: Error | null = null;

  constructor() {
    this.route.queryParams.subscribe((params) => {
      this.filters = {
        fio: params['fio'] || null,
        iin: params['iin'] || null,
        status: params['status'] || null,
        page: this.first,
        pageSize: this.rows,
      };

      this.fetchOrders();
    });
  }

  cols: TableColumn<DataEntity>[] = [
    { field: 'id', header: 'N#' },
    { field: 'dateOfReceipt', header: 'Дата' },
    { field: 'fio', header: 'ФИО' },
    { field: 'iin', header: 'ИИН' },
    { field: 'status', header: 'Статус' },
    { field: 'quantity', header: 'Кол-во' },
    { field: 'price', header: 'Стоимость' },
    { field: 'departmentId', header: 'Филиал' },
  ];

  fetchOrders() {
    this.ordersService
      .getOrders({
        params: {
          filter: this.filters,
        },
      })
      .result$.subscribe((res) => {
        this.orders = res.data?.data || [];
        this.isLoading = res.isLoading;
        this.isPending = res.isPending;
        this.error = res.error;
      });
  }
}
