export class FileModel {
  public id: string | null = null;
  public name: string | null = null;
  public src: any = null;
  public type: string | null = null;
  public isLast: boolean = false;

  public static of(id: string, src: string, type: string, name: string) {
    let ret = new FileModel();
    ret.id = id;
    ret.name = name;
    ret.type = type;
    ret.src = src;
    ret.isLast = true;
    return ret;
  }

  public static fromFile(f: File): FileModel | null {
    if (f == null) return null;
    let ret = new FileModel();
    ret.id = null;
    ret.name = f.name;
    ret.type = f.type;
    return ret;
  }

  public static fromFileModel(f: FileModel): FileModel | null {
    if (f == null) return null;
    let ret = new FileModel();
    ret.id = f.id;
    ret.name = f.name;
    ret.type = f.type;
    ret.src = f.src;

    return ret;
  }
}

export interface DocumentTypeDetail {
  type?: {
    id: string;
    title: string;
  };
  fileId: string;
  actual: boolean;
}

export interface LoaderFileModel {
  file: File;
  fileModel: FileModel;
  status: LoadFileStatusEnum;
  errorMessage: string;
}

export interface LoadFileInfo {
  fileId?: string;
  fileTitle: string;
  status?: LoadFileStatusEnum;
  errorMessage?: string;
}

export enum LoadFileStatusEnum {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
