import {
  HttpErrorResponse,
  HttpHeaders,
  HttpInterceptorFn,
} from '@angular/common/http';
import { LOCALSTORAGE_KEYS } from '../constants';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export const authorizationInterceptor: HttpInterceptorFn = (req, next) => {
  const cookieService = inject(CookieService);
  const ggToken = cookieService.get(LOCALSTORAGE_KEYS.GG_TOKEN);

  if (!!ggToken) {
    let headers: HttpHeaders = req.headers;
    headers = headers.set(LOCALSTORAGE_KEYS.GG_TOKEN, ggToken);
    req = req.clone({ headers });
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      sessionExpired(error, () => {
        cookieService.delete(LOCALSTORAGE_KEYS.GG_TOKEN);
      });

      throw error;
    })
  );
};

function sessionExpired(error: HttpErrorResponse, onAuthError?: () => void) {
  if (!environment.production) {
    return;
  }

  if (error.status === 401) {
    console.error('Left session: 401');

    removeAllCookieWithOtherDomain();

    if (onAuthError) {
      onAuthError();
    }

    window.location.href = environment.backofficeUrl;
  }
}

function removeAllCookieWithOtherDomain() {
  (function () {
    const cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split('.');
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=' +
          d.join('.') +
          ' ;path=';
        const p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  })();
}
