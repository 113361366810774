import { Routes } from '@angular/router';
import { RoleDetailsComponent } from './pages/role-details/role-details.component';
import { RolesComponent } from './pages/roles/roles.component';
import { CoinsComponent } from './pages/coins/coins.component';
import { CoinDetailsComponent } from './pages/coin-details/coin-details.component';
import { CreateCoinComponent } from './pages/create-coin/create-coin.component';
import { authGuard } from './lib/core/auth.guard';
import { OrdersComponent } from './pages/orders/orders.component';
import { CreateRoleComponent } from './pages/create-role/create-role.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { BranchesComponent } from './pages/branches/branches.component';
import { CreateBranchComponent } from './pages/create-branch/create-branch.component';
import { BranchDetailsComponent } from './pages/branch-details/branch-details.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'roles',
    pathMatch: 'full',
  },
  {
    path: 'roles',
    component: RolesComponent,
    canActivate: [authGuard],
  },
  {
    path: 'roles/:id',
    component: RoleDetailsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'create-role',
    component: CreateRoleComponent,
    canActivate: [authGuard],
  },
  {
    path: 'coins',
    component: CoinsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'coins/:id',
    component: CoinDetailsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'create-coin',
    component: CreateCoinComponent,
    canActivate: [authGuard],
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [authGuard],
  },
  {
    path: 'orders/:id',
    component: OrderDetailsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'branches',
    component: BranchesComponent,
    canActivate: [authGuard],
  },
  {
    path: 'create-branch',
    component: CreateBranchComponent,
    canActivate: [authGuard],
  },
  {
    path: 'branches/:id',
    component: BranchDetailsComponent,
    canActivate: [authGuard],
  },
];
