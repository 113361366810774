import { Component, input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { HeadingOneComponent } from '../../heading-one/heading-one.component';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [BreadcrumbModule, HeadingOneComponent],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.css',
})
export class PageLayoutComponent {
  breadcrumbItems = input<MenuItem[]>([]);
  title = input.required<string>();
}
