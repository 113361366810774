export enum OrderStatus {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  RECEIVED = 'RECEIVED',
}

export const orderStatusMenuItems: {
  label: string;
  value: OrderStatus | null;
}[] = [
  {
    label: 'Все',
    value: null,
  },
  ...Object.values(OrderStatus).map((status) => ({
    label: status,
    value: status,
  })),
];
