import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CreateProductComponent } from '@components/create-product/create-product.component';
import { ProductEditableComponent } from '@components/product-editable/product-editable.component';
import { BranchBalance } from '@model/branch-balance';
import { TableColumn } from '@model/table-column';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { BranchBalanceService } from 'src/app/services/branch-balance.service';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    TableModule,
    RouterLink,
    SkeletonModule,
    ProductEditableComponent,
    ButtonModule,
    CreateProductComponent,
  ],
  templateUrl: './product-list.component.html',
})
export class ProductListComponent {
  branchId = inject(ActivatedRoute).snapshot.paramMap.get('id');
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  productList = inject(BranchBalanceService).getBranchBalanceList({
    branchId: Number(this.branchId),
  }).result;
  cols: TableColumn<BranchBalance>[] = [
    {
      field: 'branchId',
      header: 'ID',
    },
    {
      field: 'coinName',
      header: 'Наименование монеты',
    },
    {
      field: 'quantity',
      header: 'Остаток',
    },
  ];
  selectedProductId: number | null = null;
  createProductVisisble: boolean = false;

  constructor() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const productId = +params['productId'];
      this.selectedProductId = productId;
    });
  }

  handleDetailsClick(id: number) {
    this.router.navigate([], {
      queryParams: {
        productId: id,
      },
      queryParamsHandling: 'merge',
    });
  }

  handleCreateProductClick() {
    this.createProductVisisble = true;
  }

  handleCreateProductClose() {
    this.createProductVisisble = false;
  }
}
