import { Component, inject } from '@angular/core';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { BranchService } from 'src/app/services/branch.service';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-create-branch',
  standalone: true,
  imports: [
    PageLayoutComponent,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    RouterLink,
  ],
  templateUrl: './create-branch.component.html',
})
export class CreateBranchComponent {
  createBranch = inject(BranchService).saveBranch();
  createBranchResult = this.createBranch.result;
  router = inject(Router);

  branchForm = new FormGroup({
    id: new FormControl('', [Validators.required, Validators.min(1)]),
    name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
  });

  handleSubmit() {
    if (!this.branchForm.valid) {
      this.branchForm.markAllAsTouched();
      return;
    }

    this.createBranch.mutate(
      {
        id: +this.branchForm.get('id')?.value!,
        name: this.branchForm.get('name')?.value!,
        address: this.branchForm.get('address')?.value!,
        city: this.branchForm.get('city')?.value!,
      },
      {
        onSuccess: () => {
          this.router.navigate(['/branches']);
        },
      }
    );
  }
}
