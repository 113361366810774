import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { MenuItem, MessageService } from 'primeng/api';
import { rolesDropdownOptions } from 'src/app/lib/constants';
import { UsersService } from 'src/app/services/users.service';
import { User } from '@model/users';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';

@Component({
  selector: 'app-create-role',
  standalone: true,
  imports: [
    HeadingOneComponent,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    CommonModule,
    ButtonModule,
    PasswordModule,
    RouterLink,
    BreadcrumbModule,
    PageLayoutComponent,
  ],
  templateUrl: './create-role.component.html',
  styleUrl: './create-role.component.css',
})
export class CreateRoleComponent {
  messageService = inject(MessageService);
  userTypes = rolesDropdownOptions;
  saveUser = inject(UsersService).saveUser();
  saveUserResult = this.saveUser.result;
  breadcrumbItems: MenuItem[] = [
    {
      label: 'Роли',
      routerLink: ['/roles'],
    },
    {
      label: 'Создать роль',
      routerLink: ['/create-role'],
    },
  ];
  branchIds = [
    {
      name: 'Выберите филиал',
      code: '',
    },
    {
      name: '1332',
      code: '1332',
    },
  ];
  roleDetailsForm = new FormGroup({
    type: new FormControl('', [Validators.required]),
    fullName: new FormControl('', [Validators.required]),
    branchId: new FormControl('', [Validators.required]),
    city: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  onSubmit() {
    if (this.roleDetailsForm.invalid) {
      this.roleDetailsForm.markAllAsTouched();
      return;
    }

    const userBody: Partial<User> = {
      username: this.roleDetailsForm.get('email')?.value as string,
      name: this.roleDetailsForm
        .get('fullName')
        ?.value?.split(' ')[0] as string,
      surname: this.roleDetailsForm
        .get('fullName')
        ?.value?.split(' ')[1] as string,
      email: this.roleDetailsForm.get('email')?.value as string,
      department: this.roleDetailsForm.get('branchId')?.value as string,
      nbrkRole: this.roleDetailsForm.get('type')?.value as string,
      password: this.roleDetailsForm.get('password')?.value as string,
      repeatPassword: this.roleDetailsForm.get('password')?.value as string,
    };

    this.saveUser.mutate({
      userBody,
    });
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Скопировано',
    });
  }
}
