import { Component, Input } from '@angular/core';
import { cn } from '../../lib/utils/cn';

@Component({
  selector: 'heading-one',
  standalone: true,
  imports: [],
  templateUrl: './heading-one.component.html',
  styleUrl: './heading-one.component.css',
})
export class HeadingOneComponent {
  @Input() class = '';

  renderClass() {
    return cn('text-3xl font-bold', this.class);
  }
}
