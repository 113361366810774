<section class="flex flex-col gap-8">
  @if (createProductVisisble) {
  <app-create-product
    (onSubmit)="handleCreateProductClose()"
    (onClose)="handleCreateProductClose()"
  />
  } @else if (selectedProductId) {<app-product-editable />} @else { @if
  (productList().isError) {
  <p class="text-red-400">
    {{ productList().error?.message || "Что-то пошло не так" }}
  </p>

  } @if (productList().isLoading) {
  <p-skeleton height="500px" width="100%" />
  } @if (productList().data) {
  <p-table
    [value]="productList().data || []"
    [columns]="cols"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-gridlines p-datatable-sm"
    scrollHeight="50vh"
    [scrollable]="true"
    class="w-full"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (item of columns; track item.id) {
        <th class="min-w-24">{{ item.header }}</th>
        }
        <th class="w-5"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track col.field) { @if (col.field === 'coinName')
        {
        <td>
          <a
            class="text-primary underline hover:opacity-80 transition-colors"
            [routerLink]="'/coins/' + rowData.coinId"
          >
            {{ rowData[col.field] }}
          </a>
        </td>

        } @else {

        <td>{{ rowData[col.field] }}</td>
        } }
        <td>
          <button
            (click)="handleDetailsClick(rowData.id)"
            class="text-primary underline hover:opacity-80 transition-colors"
          >
            Детали
          </button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">
          <p class="text-center">Товаров нет</p>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex items-center gap-2">
    <p-button
      [label]="'Добавить товар'"
      (click)="handleCreateProductClick()"
    ></p-button>
  </div>

  } }
</section>
