@if (branchDetails().isError) {
<p class="text-red-400">
  {{ branchDetails().error?.message || "Что-то пошло не так" }}
</p>
} @if (branchDetails().isLoading) {
<p-skeleton height="196px" width="100%" />
} @if (branchDetails().data) {
<form
  class="w-full flex flex-col h-full gap-8"
  [formGroup]="branchDetailsForm()"
  (ngSubmit)="handleSubmit()"
>
  <div class="w-full grid grid-cols-2 gap-4">
    <div class="flex flex-col gap-2">
      <label for="name" class="font-semibold"> Название </label>
      <input id="name" formControlName="name" pInputText />
      <p class="text-red-400">
        {{
          branchDetailsForm().get("name")?.invalid &&
          (branchDetailsForm().get("name")?.touched ||
            branchDetailsForm().get("name")?.dirty)
            ? "Поле обязательно для заполнения"
            : ""
        }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <label for="city" class="font-semibold"> Город </label>
      <input id="city" formControlName="city" pInputText />
      <p class="text-red-400">
        {{
          branchDetailsForm().get("city")?.invalid &&
          (branchDetailsForm().get("city")?.touched ||
            branchDetailsForm().get("city")?.dirty)
            ? "Поле обязательно для заполнения"
            : ""
        }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <label for="id" class="font-semibold"> ID </label>
      <input id="id" readonly formControlName="id" pInputText />
      <p class="text-red-400">
        {{
          branchDetailsForm().get("id")?.invalid &&
          (branchDetailsForm().get("id")?.touched ||
            branchDetailsForm().get("id")?.dirty)
            ? "Поле обязательно для заполнения"
            : ""
        }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <label for="address" class="font-semibold"> Адрес </label>
      <input id="address" formControlName="address" pInputText />
      <p class="text-red-400">
        {{
          branchDetailsForm().get("address")?.invalid &&
          (branchDetailsForm().get("address")?.touched ||
            branchDetailsForm().get("address")?.dirty)
            ? "Поле обязательно для заполнения"
            : ""
        }}
      </p>
    </div>
  </div>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-4">
      <p-button
        [disabled]="
          updateBranchResult().isPending || !branchDetailsForm().dirty
        "
        type="submit"
        [label]="'Сохранить'"
      ></p-button>

      <a routerLink="/branches" class="p-button p-button-outlined font-bold">
        Закрыть
      </a>
    </div>

    <p-button
      [disabled]="deleteBranchResult().isPending"
      (onClick)="handleDelete()"
      [label]="'Удалить'"
      severity="danger"
      type="button"
    ></p-button>
  </div>
</form>

}
