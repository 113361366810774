<header class="py-4 px-8 flex items-center justify-end">
  <p-menu #menu [model]="items" [popup]="true" />
  <button (click)="menu.toggle($event)" class="flex items-center gap-4 p-2">
    <div class="flex flex-col items-start text-sm">
      <span class="font-medium"> {{ authInfo()?.displayName }} </span>
      <span> {{ authInfo()?.role }} </span>
    </div>
    <p-avatar [label]="authInfo()?.accountName?.charAt(0)" size="large" />
  </button>
</header>
