import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ScrollerModule } from 'primeng/scroller';
import { AixLogoComponent } from '../aix-logo/aix-logo.component';
import { NbrkLogoComponent } from '../nbrk-logo/nbrk-logo.component';

@Component({
  selector: 'app-sidebar-content',
  standalone: true,
  imports: [
    ScrollerModule,
    RouterLink,
    RouterLinkActive,
    AixLogoComponent,
    NbrkLogoComponent,
  ],
  templateUrl: './sidebar-content.component.html',
  styleUrl: './sidebar-content.component.css',
})
export class SidebarContentComponent {
  linkItems = [
    {
      label: 'Роли',
      routerLink: 'roles',
    },
    {
      label: 'Филиалы',
      routerLink: 'branches',
    },
    {
      label: 'Товары',
      routerLink: 'goods',
    },
    {
      label: 'Заказы',
      routerLink: 'orders',
    },
    {
      label: 'Монеты',
      routerLink: 'coins',
    },
  ];

  asLinkItem(item: any) {
    return item as { label: string; routerLink: string };
  }
}
