import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { injectMutation, injectQuery } from '@ngneat/query';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class OrderReportService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #messageService = inject(MessageService);

  getReport() {
    return this.#mutation({
      mutationFn: ({ id }: { id: number }) =>
        this.#http.get<Blob>(environment.apiUrlPrefix + 'nbrk/order-report', {
          params: { id },
          withCredentials: true,
          responseType: 'blob' as 'json',
        }),
      onError: (err) => {
        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: err.message,
        });
      },
    });
  }
}
