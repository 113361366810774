import { Component, inject } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TableColumn } from '@model/table-column';
import { RouterLink } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-manage-roles',
  standalone: true,
  imports: [TableModule, ButtonModule, RouterLink, SkeletonModule],
  templateUrl: './manage-roles.component.html',
  styleUrl: './manage-roles.component.css',
})
export class ManageRolesComponent {
  testData = inject(UsersService).getUserList().result;

  cols: TableColumn[] = [
    { field: 'id', header: 'N#' },
    { field: 'nbrkRole', header: 'Название' },
    { field: 'email', header: 'Почта' },
    { field: 'name', header: 'Имя' },
    { field: 'surname', header: 'Фамилия' },
    { field: 'department', header: 'Филиал' },
    { field: 'city', header: 'Город' },
  ];
}
