import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { FileModel } from '@model/file';
import { injectMutation, injectQuery } from '@ngneat/query';
import { MessageService } from 'primeng/api';
import { QUERY_KEYS } from '../lib/constants';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  #http = inject(HttpClient);
  #mutation = injectMutation();
  messageService = inject(MessageService);
  #query = injectQuery();

  uploadFile() {
    return this.#mutation({
      mutationFn: (data: { file: File; fileId: string; src: string }) => {
        const title = data.file.name;
        const fileModel = FileModel.of(
          data.fileId,
          data.src,
          data.file.type,
          title
        );

        const dataToSend = new FormData();

        dataToSend.append('file', JSON.stringify(fileModel));
        dataToSend.append('isLast', 'true');

        return this.#http.post<string>(
          environment.apiUrlPrefix + 'file/save',
          dataToSend,
          {
            withCredentials: true,
          }
        );
      },

      onSuccess: (res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Успех',
          detail: 'Файл добавлен',
        });

        return res;
      },

      onError: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.message,
        });
      },
    });
  }

  getResource(id: string) {
    return this.#query({
      queryKey: [QUERY_KEYS.FILE, id],
      enabled: !!id,
      queryFn: () =>
        this.#http.get(
          environment.apiUrlPrefix + 'file/download?fileId=' + id,
          {
            // withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob',
            observe: 'response',
          }
        ),
    });
  }
}
