import { Component, computed, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { BranchBalanceService } from 'src/app/services/branch-balance.service';
import { DropdownModule } from 'primeng/dropdown';
import { CoinService } from 'src/app/services/coin.service';

@Component({
  selector: 'app-product-editable',
  standalone: true,
  imports: [
    SkeletonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    RouterLink,
    DropdownModule,
  ],
  templateUrl: './product-editable.component.html',
})
export class ProductEditableComponent {
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  branchId = this.activatedRoute.snapshot.paramMap.get('id');
  id = this.activatedRoute.snapshot.queryParams['productId'];
  branchBalanceService = inject(BranchBalanceService);
  productDetailsDef = this.branchBalanceService.getBranchBalance(this.id);
  productDetails = this.productDetailsDef.result;

  updateProductDetails = this.branchBalanceService.updateBranchBalance();
  updateProductDetailsResult = this.updateProductDetails.result;

  coins = inject(CoinService).getCoins().result;
  coinDropdownOptions = computed(() =>
    this.coins().data?.map((coin) => ({ label: coin.coinName, value: coin.id }))
  );

  productDetailsForm = computed(
    () =>
      new FormGroup({
        coinId: new FormControl(this.productDetails().data?.coinId, [
          Validators.required,
        ]),
        quantity: new FormControl(this.productDetails().data?.quantity, [
          Validators.required,
        ]),
      })
  );

  constructor() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const productId = +params['productId'];
      this.id = productId;
      this.productDetailsDef.updateOptions(
        this.branchBalanceService.getBranchBalanceOptions(productId)
      );
    });
  }

  handleSubmit() {
    if (!this.productDetailsForm().valid) {
      return;
    }

    this.updateProductDetails.mutate({
      body: {
        id: Number(this.productDetails().data?.id),
        coinId: Number(this.productDetailsForm().value.coinId),
        quantity: Number(this.productDetailsForm().value.quantity),
        branchId: Number(this.branchId),
      },
    });
  }

  handleClose() {
    this.router.navigate([], {
      queryParams: { productId: null },
      queryParamsHandling: 'merge',
    });
  }
}
