<div class="flex flex-wrap w-full h-full">
  <aside class="basis-[13rem] grow bg-gray-50">
    <ng-content select="[sidebar]"> </ng-content>
  </aside>
  <div class="basis-0 grow-[999] flex flex-col h-full overflow-hidden">
    <app-header></app-header>
    <main class="w-full overflow-y-scroll grow max-h-full">
      <div class="p-4 max-w-7xl mx-auto">
        <ng-content select="[main-content]"></ng-content>
      </div>
    </main>
  </div>
</div>
