import { Component, inject, input, output } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { DropdownModule } from 'primeng/dropdown';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { OrderFilters } from '@model/order-filters';
import { orderStatusMenuItems } from '@model/order-status';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    InputTextModule,
    IconFieldModule,
    InputIconModule,
    DropdownModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.css',
})
export class FiltersComponent {
  branches = [];
  statuses = orderStatusMenuItems;
  onSubmit = output<OrderFilters>();
  onReset = output<OrderFilters>();

  router = inject(Router);
  route = inject(ActivatedRoute);
  filtersForm = new FormGroup({
    status: new FormControl(
      this.route.snapshot.queryParamMap.get('status') || ''
    ),
    branch: new FormControl(
      this.route.snapshot.queryParamMap.get('branch') || ''
    ),
    iin: new FormControl(this.route.snapshot.queryParamMap.get('iin') || ''),
    fio: new FormControl(this.route.snapshot.queryParamMap.get('fio') || ''),
  });

  submitForm() {
    this.setQueryParams();
    this.onSubmit.emit(this.filtersForm.value as OrderFilters);
  }

  resetFilters() {
    this.filtersForm.reset();
    this.setQueryParams();
    this.onReset.emit(this.filtersForm.value as OrderFilters);
  }

  setQueryParams() {
    const queryParams: Params = {
      ...this.filtersForm.value,
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
