<div class="flex-col items-start flex gap-8">
  @if (testData().isError) {
  <p class="text-red-400">
    {{ testData().error?.message || "Что-то пошло не так" }}
  </p>

  } @if (testData().isLoading) {
  <p-skeleton height="50vh" width="100%" />

  } @if (!!testData().data) {
  <p-table
    [value]="testData().data || []"
    styleClass="p-datatable-gridlines p-datatable-sm"
    scrollHeight="50vh"
    [scrollable]="true"
    [columns]="cols"
    class="grow h-full w-full"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (item of columns; track item.id) {
        <th class="min-w-24">{{ item.header }}</th>
        }
        <th class="w-5"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track col.field) {
        <td>{{ rowData[col.field] }}</td>
        }
        <td>
          <a
            class="text-primary underline hover:opacity-80 transition-colors"
            [routerLink]="rowData.id"
          >
            Редактировать
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
  }

  <a routerLink="/create-role" class="p-button p-button-sm font-bold">
    Добавить роль
  </a>
</div>
