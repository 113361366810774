<app-page-layout [title]="'Филиал ' + id">
  <p-tabView (onChange)="handleTabClick($event)" [activeIndex]="+activeTab">
    <p-tabPanel header="Филиал">
      <app-branch-editable />
    </p-tabPanel>
    <p-tabPanel header="Товары">
      <app-product-list />
    </p-tabPanel>
  </p-tabView>
</app-page-layout>
