import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import {
  injectQuery,
  injectMutation,
  injectQueryClient,
  queryOptions,
} from '@ngneat/query';
import { MessageService } from 'primeng/api';
import { QUERY_KEYS } from '../lib/constants';
import { BranchBalance } from '@model/branch-balance';

type SaveBranchBalanceBody = {
  coinId: number;
  branchId: number;
  quantity: number;
};

type UpdateBranchBalanceBody = SaveBranchBalanceBody & { id: number };

@Injectable({
  providedIn: 'root',
})
export class BranchBalanceService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #messageService = inject(MessageService);
  #queryClient = injectQueryClient();

  getBranchBalanceList(options?: { branchId?: number }) {
    return this.#query<BranchBalance[]>({
      queryKey: [QUERY_KEYS.BRANCH_BALANCE_LIST, options?.branchId],
      queryFn: () =>
        this.#http.get<BranchBalance[]>(
          environment.apiUrlPrefix + 'nbrk/branch-balance-list',
          {
            withCredentials: true,
            params: {
              branchId: options?.branchId?.toString() || '',
            },
          }
        ),
    });
  }

  getBranchBalance(id: number) {
    return this.#query({
      ...this.getBranchBalanceOptions(id),
    });
  }

  getBranchBalanceOptions(id: number) {
    return queryOptions<BranchBalance>({
      queryKey: [QUERY_KEYS.BRANCH_BALANCE, id],
      queryFn: () =>
        this.#http.get<BranchBalance>(
          environment.apiUrlPrefix + 'nbrk/branch-balance',
          {
            withCredentials: true,
            params: {
              id,
            },
          }
        ),
      enabled: !!id,
    });
  }

  saveBranchBalance() {
    return this.#mutation({
      mutationFn: ({ body }: { body: SaveBranchBalanceBody }) =>
        this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/save-branch-balance',
          new URLSearchParams({
            request: JSON.stringify(body),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        ),
      onSuccess: () => {
        this.#messageService.add({
          severity: 'success',
          detail: 'Баланс добавлен',
        });
      },
      onError: (error) => {
        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.message,
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BRANCH_BALANCE_LIST],
        });
      },
    });
  }

  updateBranchBalance() {
    return this.#mutation({
      mutationFn: ({ body }: { body: UpdateBranchBalanceBody }) =>
        this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/save-branch-balance',
          new URLSearchParams({
            request: JSON.stringify(body),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        ),
      onSuccess: () => {
        this.#messageService.add({
          severity: 'success',
          detail: 'Баланс обновлен',
        });
      },
      onError: (error) => {
        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.message,
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BRANCH_BALANCE_LIST],
        });
      },
    });
  }
}
