import { Component, inject } from '@angular/core';
import { Branch } from '@model/branch';
import { TableColumn } from '@model/table-column';
import { BranchService } from 'src/app/services/branch.service';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-branches',
  standalone: true,
  imports: [PageLayoutComponent, SkeletonModule, TableModule, RouterLink],
  templateUrl: './branches.component.html',
})
export class BranchesComponent {
  branches = inject(BranchService).getBranchList().result;

  cols: TableColumn<Branch>[] = [
    {
      field: 'id',
      header: 'ID',
    },
    {
      field: 'name',
      header: 'Название',
    },
    {
      field: 'address',
      header: 'Адрес',
    },
    {
      field: 'city',
      header: 'Город',
    },
  ];
}
