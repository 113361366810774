<app-page-layout
  [title]="'Заказ' + ' ' + id"
  [breadcrumbItems]="breadcrumbItems"
>
  @if (orderDetails().isError) {
  <p class="text-red-400">
    {{ orderDetails().error?.message || "Что-то пошло не так" }}
  </p>
  } @if (orderDetails().isLoading) {
  <div class="grid grid-cols-2 gap-12">
    <p-skeleton height="294px" width="384px" />
    <p-skeleton height="294px" width="384px" />
    <p-skeleton height="218px" width="384px" />
  </div>
  } @if (!!orderDetails().isSuccess) {
  <div class="grid grid-cols-2 gap-12">
    <div class="w-full max-w-96 flex flex-col gap-4">
      <h3 class="text-xl font-bold">Заказчик</h3>
      <div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">ФИО</h4>
          <span class="border p-3">
            {{ orderDetails().data?.fio }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">ИИН</h4>
          <span class="border p-3">
            {{ orderDetails().data?.iin }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Телефон</h4>
          <span class="border p-3">
            {{ orderDetails().data?.username }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">email</h4>
          <span class="border p-3">
            {{ orderDetails().data?.email }}
          </span>
        </div>
      </div>
    </div>
    <div class="w-full max-w-96 flex flex-col gap-4">
      <h3 class="text-xl font-bold">Товар/монета</h3>
      <div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Наименование</h4>
          <span class="border p-3">
            {{ orderDetails().data?.coinName }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Цена</h4>
          <span class="border p-3">
            {{ orderDetails().data?.price }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Количество</h4>
          <span class="border p-3">
            {{ orderDetails().data?.quantity }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Сумма</h4>
          <span class="border p-3">
            {{ orderDetails().data?.price }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Статус</h4>
          <span class="border p-3 font-bold">
            {{ orderDetails().data?.status }}
          </span>
        </div>
      </div>
    </div>
    <div class="w-full max-w-96 flex flex-col gap-4">
      <h3 class="text-xl font-bold">Детали</h3>
      <div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">ID филиала</h4>
          <span class="border p-3">
            {{ orderDetails().data?.departmentId }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Город</h4>
          <span class="border p-3"> </span>
        </div>
        <div class="grid grid-cols-2">
          <h4 class="font-semibold border p-3">Дата получения</h4>
          <span class="border p-3">
            {{ orderDetails().data?.dateOfReceipt | date : "dd.MM.yyyy" }}
          </span>
        </div>
      </div>
    </div>
    <div class="w-full max-w-96 flex flex-col gap-5">
      <p-button
        [label]="'Распечатать PDF'"
        styleClass="w-full"
        [outlined]="true"
        (onClick)="onGenerateOrderReport()"
        [disabled]="
          orderDetails().data?.status !== 'RECEIVED' ||
          geneateOrderReportResult().isPending
        "
      ></p-button>
      <p-button
        [label]="'Выдано'"
        styleClass="w-full"
        (onClick)="onReceiveCoin()"
        [disabled]="
          orderDetails().data?.status !== 'PAID' ||
          receiveCoinResult().isPending
        "
      ></p-button>
    </div>
  </div>
  }
</app-page-layout>
