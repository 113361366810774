import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { injectQuery, injectQueryClient } from '@ngneat/query';
import { QUERY_KEYS } from '../lib/constants';
import { environment } from '@environment/environment';
import { Coin, CoinBody } from '@model/coin';
import { injectMutation } from '@ngneat/query';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CoinService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  messageService = inject(MessageService);
  router = inject(Router);
  #queryClient = injectQueryClient();

  getCoins() {
    return this.#query({
      queryKey: [QUERY_KEYS.COINS],
      queryFn: () =>
        this.#http.get<Coin[]>(
          environment.apiUrlPrefix + 'nbrk/get-coin-list',
          {
            withCredentials: true,
          }
        ),
    });
  }

  getSingleCoin(id: string) {
    return this.#query({
      queryKey: [QUERY_KEYS.COINS, id],
      queryFn: () =>
        this.#http.get<Coin>(environment.apiUrlPrefix + `nbrk/get-coin`, {
          withCredentials: true,
          params: { id },
        }),
    });
  }

  saveCoin() {
    return this.#mutation({
      mutationFn: ({ coinBody }: { coinBody: CoinBody }) => {
        return this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/save-coin',
          new URLSearchParams({
            request: JSON.stringify(coinBody),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
      },

      onSuccess: (res, { coinBody }) => {
        if (!!coinBody.id) {
          this.messageService.add({
            severity: 'success',
            detail: 'Монета обновлена',
          });
        } else {
          this.messageService.add({
            severity: 'success',
            detail: 'Монета добавлена',
          });
          this.router.navigate(['/coins']);
        }
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries();
      },
      onError: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.message,
        });
      },
    });
  }
}
