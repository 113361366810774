@if (productDetails().isError) {
<p class="text-red-400">
  {{ productDetails().error?.message || "Что-то пошло не так" }}
</p>

} @if (productDetails().isLoading) {
<p-skeleton
  height="200px"
  class="max-w-xl w-full"
  styleClass="w-full max-w-xl"
/>

} @if (productDetails().data) {

<form
  [formGroup]="productDetailsForm()"
  (ngSubmit)="handleSubmit()"
  class="flex flex-col gap-4 max-w-xl"
>
  <div class="flex flex-col gap-2">
    <label for="coinId" class="font-bold">Наименование монеты</label>
    <p-dropdown
      formControlName="coinId"
      [options]="coinDropdownOptions()"
      optionLabel="label"
      optionValue="value"
      placeholder="Выберите монету"
      class="w-full"
      styleClass="w-full"
    />
  </div>
  <div class="flex flex-col gap-2">
    <label for="quantity" class="font-bold">Количество в филиале</label>
    <input pInputText formControlName="quantity" id="quantity" />
  </div>

  <div class="flex items-center gap-2">
    <p-button
      type="submit"
      label="Сохранить"
      [disabled]="
        !productDetailsForm().dirty || updateProductDetailsResult().isPending
      "
    ></p-button>
    <button
      type="button"
      (click)="handleClose()"
      class="p-button p-button-outlined font-bold"
    >
      Закрыть
    </button>
  </div>
</form>
}
