<app-page-layout [title]="'Добавить филиал'">
  <form
    class="w-full flex flex-col h-full gap-8"
    [formGroup]="branchForm"
    (ngSubmit)="handleSubmit()"
  >
    <div class="w-full grid grid-cols-2 gap-4">
      <div class="flex flex-col gap-2">
        <label for="name" class="font-semibold"> Название </label>
        <input id="name" formControlName="name" pInputText />
        <p class="text-red-400">
          {{
            branchForm.get("name")?.invalid &&
            (branchForm.get("name")?.touched || branchForm.get("name")?.dirty)
              ? "Поле обязательно для заполнения"
              : ""
          }}
        </p>
      </div>
      <div class="flex flex-col gap-2">
        <label for="city" class="font-semibold"> Город </label>
        <input id="city" formControlName="city" pInputText />
        <p class="text-red-400">
          {{
            branchForm.get("city")?.invalid &&
            (branchForm.get("city")?.touched || branchForm.get("city")?.dirty)
              ? "Поле обязательно для заполнения"
              : ""
          }}
        </p>
      </div>
      <div class="flex flex-col gap-2">
        <label for="id" class="font-semibold"> ID </label>
        <p-inputNumber id="id" formControlName="id" />
        <p class="text-red-400">
          {{
            branchForm.get("id")?.invalid &&
            (branchForm.get("id")?.touched || branchForm.get("id")?.dirty)
              ? "Поле обязательно для заполнения"
              : ""
          }}
        </p>
      </div>
      <div class="flex flex-col gap-2">
        <label for="address" class="font-semibold"> Адрес </label>
        <input id="address" formControlName="address" pInputText />
        <p class="text-red-400">
          {{
            branchForm.get("address")?.invalid &&
            (branchForm.get("address")?.touched ||
              branchForm.get("address")?.dirty)
              ? "Поле обязательно для заполнения"
              : ""
          }}
        </p>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <p-button
        type="submit"
        [disabled]="createBranchResult().isPending"
        [label]="'Сохранить'"
      ></p-button>

      <a routerLink="/branches" class="p-button p-button-outlined font-bold">
        Закрыть
      </a>
    </div>
  </form>
</app-page-layout>
