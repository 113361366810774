import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TableColumn } from '@model/table-column';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { CoinService } from 'src/app/services/coin.service';

@Component({
  selector: 'app-manage-coins',
  standalone: true,
  imports: [
    InputTextModule,
    InputIconModule,
    IconFieldModule,
    TableModule,
    RouterLink,
    ButtonModule,
    SkeletonModule,
  ],
  templateUrl: './manage-coins.component.html',
  styleUrl: './manage-coins.component.css',
})
export class ManageCoinsComponent {
  coins = inject(CoinService).getCoins().result;

  cols: TableColumn[] = [
    { field: 'id', header: 'ID' },
    { field: 'coinName', header: 'Наименование' },
    { field: 'nominal', header: 'Номинал' },
    { field: 'weight', header: 'Вес(oz)' },
    { field: 'diameter', header: 'Диаметр' },
    { field: 'buyLimit', header: 'Ограничение на покупку' },
    { field: 'availability', header: 'Тираж' },
  ];
}
