import { Component, inject } from '@angular/core';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchService } from 'src/app/services/branch.service';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { BranchEditableComponent } from '../../components/branch-editable/branch-editable.component';
import { ProductListComponent } from '../../components/product-list/product-list.component';

@Component({
  selector: 'app-branch-details',
  standalone: true,
  imports: [
    PageLayoutComponent,
    TabViewModule,
    BranchEditableComponent,

    ProductListComponent,
  ],
  templateUrl: './branch-details.component.html',
})
export class BranchDetailsComponent {
  id = inject(ActivatedRoute).snapshot.paramMap.get('id');
  branchDetails = inject(BranchService).getBranch(Number(this.id)).result;
  router = inject(Router);
  activeTab = inject(ActivatedRoute).snapshot.queryParamMap.get('tab') || 0;

  handleTabClick(e: TabViewChangeEvent) {
    this.activeTab = e.index;
    this.router.navigate([], {
      queryParams: { tab: e.index },
      queryParamsHandling: 'merge',
    });
  }
}
