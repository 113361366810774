import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { MenuItem, MessageService } from 'primeng/api';
import { rolesDropdownOptions } from 'src/app/lib/constants';
import { UsersService } from 'src/app/services/users.service';
import { SkeletonModule } from 'primeng/skeleton';
import { User } from '@model/users';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';

@Component({
  selector: 'app-role-details',
  standalone: true,
  imports: [
    HeadingOneComponent,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    CommonModule,
    ButtonModule,
    PasswordModule,
    RouterLink,
    SkeletonModule,
    BreadcrumbModule,
    PageLayoutComponent,
  ],
  templateUrl: './role-details.component.html',
  styleUrl: './role-details.component.css',
})
export class RoleDetailsComponent {
  messageService = inject(MessageService);
  activatedRoute = inject(ActivatedRoute);
  breadcrumbItems: MenuItem[] = [
    {
      label: 'Роли',
      routerLink: ['/roles'],
    },
    {
      label: 'Пользователь НБРК',
      routerLink: ['/roles', this.activatedRoute.snapshot.paramMap.get('id')],
    },
  ];

  saveUser = inject(UsersService).saveUser();
  saveUserResult = this.saveUser.result;
  id = this.activatedRoute.snapshot.paramMap.get('id');
  user = inject(UsersService).getSingleUser(Number(this.id)).result;
  userTypes = rolesDropdownOptions;
  branchIds = [
    {
      name: 'Выберите филиал',
      code: '',
    },
    {
      name: '1332',
      code: '1332',
    },
  ];

  roleDetailsForm = computed(
    () =>
      new FormGroup({
        type: new FormControl(this.user().data?.nbrkRole || '', [
          Validators.required,
        ]),
        fullName: new FormControl(
          this.user().data?.name + ' ' + this.user().data?.surname || '',
          [Validators.required]
        ),
        branchId: new FormControl(this.user().data?.department || '', [
          Validators.required,
        ]),
        city: new FormControl(''),
        email: new FormControl(this.user().data?.email, [
          Validators.required,
          Validators.email,
        ]),
        password: new FormControl(''),
      })
  );

  onSubmit() {
    if (this.roleDetailsForm().invalid) {
      this.roleDetailsForm().markAllAsTouched();
      return;
    }

    const userBody: User = {
      id: this.user().data?.id as number,
      username: this.roleDetailsForm().get('email')?.value as string,
      name: this.roleDetailsForm()
        .get('fullName')
        ?.value?.split(' ')[0] as string,
      surname: this.roleDetailsForm()
        .get('fullName')
        ?.value?.split(' ')[1] as string,
      email: this.roleDetailsForm().get('email')?.value as string,
      department: this.roleDetailsForm().get('branchId')?.value as string,
      nbrkRole: this.roleDetailsForm().get('type')?.value as string,
      password: this.roleDetailsForm().get('password')?.value as string,
      repeatPassword: this.roleDetailsForm().get('password')?.value as string,
    };

    this.saveUser.mutate({
      userBody,
    });
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Скопировано',
    });
  }
}
