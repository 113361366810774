import { Component } from '@angular/core';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { ManageCoinsComponent } from '@components/manage-coins/manage-coins.component';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';

@Component({
  selector: 'app-coins',
  standalone: true,
  imports: [HeadingOneComponent, ManageCoinsComponent, PageLayoutComponent],
  templateUrl: './coins.component.html',
  styleUrl: './coins.component.css',
})
export class CoinsComponent {}
